export const isContainingText = (
  searchedText: string,
  containingText: string
) => {
  if (!searchedText || !containingText) {
    return false;
  }

  return searchedText.toLowerCase().includes(containingText.toLowerCase());
};
