import React from "react";
import PropTypes from "prop-types";

const RadioButton = ({
  id,
  // TODO: rename -> "value"
  radioValue,
  // TODO: rename -> "onChange"/"onSelect"
  handlerFuction,
  currentValue,
  // TODO: rename -> "label"
  subtitle,
  labelClassName,
}) => {
  return (
    <span className="flex items-center justify-center">
      <input
        id={id}
        type="radio"
        value={radioValue}
        checked={currentValue === radioValue}
        onChange={(e) => handlerFuction(e)}
      />
      <label htmlFor={id} className={labelClassName}>
        {subtitle}
      </label>
    </span>
  );
};

export default RadioButton;

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  radioValue: PropTypes.string.isRequired,
  handlerFuction: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
};
