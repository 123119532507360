import React from "react";
import PropTypes from "prop-types";

const RadioButtonsWrapper = ({
  groupClassName,
  groupTitle,
  gridClassName,
  children,
}) => {
  return (
    <div className={groupClassName}>
      <legend>{groupTitle}</legend>
      <fieldset className={gridClassName}>{children}</fieldset>
    </div>
  );
};

export default RadioButtonsWrapper;

RadioButtonsWrapper.propTypes = {
  groupClassName: PropTypes.string,
  groupTitle: PropTypes.string,
  gridClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};
