import React, { useContext, useState } from "react";

import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { faCat, faDog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import orderBy from "lodash/orderBy";

import Card from "../components/Card";
import ErrorMessage from "../components/ErrorMessage";
import Headline from "../components/Headline";
import Layout from "../components/Layout";
import RadioButton from "../components/RadioButton";
import RadioButtonsWrapper from "../components/RadioButtonGroupWrapper";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import { ThemeContext } from "../context/ThemeContext";
import useTheme from "../hook/useTheme";
import { isContainingText } from "../shared/utils/containingText";
import { dateDisplay } from "../shared/utils/dateDisplay";
import { removeDomElement } from "../shared/utils/removeDomElement";
import { createAnimalDetailsUrl } from "../shared/utils/urls";

import { createStringAge } from "./../shared/utils/AgeCreator";

const radioWrapper =
  "w-full lg:w-auto lg:mr-10 font-bold text-center my-6 lg:my-auto";
const radioGrid = "grid grid-cols-2 gap-4 p-4";
const radioButtonLabelClassName = "ml-3";

const AdoptionsPage = ({ data }) => {
  const { isHeightContrastEnable } = useContext(ThemeContext);
  const theme = useTheme(3);

  const [filter, setFilter] = useState("");
  const animals = data.directus.animal;

  const filteredAnimals = animals.filter((item) =>
    filter ? isContainingText(item?.kind, filter) : true
  );

  const filteredAndSortedAnimals = orderBy(
    filteredAnimals,
    (animal) => new Date(animal.date_admission),
    "desc"
  );

  const { heroAnimal } = data.directus;
  const heroImage = heroAnimal?.[0].photos?.[0]?.directus_files_id;

  return (
    <Layout theme={theme} pageTitle="Adopcje">
      <SEO
        urlPath="/adopcje/"
        imagePath={heroImage?.imageFile?.previewImage?.resize?.src}
        description="Psy i koty do adopcji w Miejskim Schronisku dla Zwierząt w Tychach. Wiele zwierzaków czeka na adopcję – daj im szansę!"
      />
      <div className="flex justify-center">
        <div className="mx-4 w-full max-w-screen-2xl md:mx-10 md:mt-8">
          <SectionContainer
            className={clsx(
              theme.bgStyleLight,
              "mx-auto rounded-t-md px-3 py-5"
            )}
          >
            <Headline
              title="Zwierzaki do adopcji"
              level={1}
              className="mb-4 font-bold"
            />
            <form className="mb-4 flex w-full flex-col items-center justify-center bg-white p-6 text-xl">
              <RadioButtonsWrapper
                groupTitle="Jakiego zwierzaka chcesz przygarnąć?"
                groupClassName={radioWrapper}
                gridClassName={radioGrid}
              >
                <RadioButton
                  id="filter-option-cat"
                  radioValue="cat"
                  subtitle={
                    <span className="inline-flex items-center">
                      <span>Kotka!</span>
                      <FontAwesomeIcon
                        className={clsx(theme.textAccentLight, "ml-2 text-3xl")}
                        icon={faCat}
                      />
                    </span>
                  }
                  labelClassName={radioButtonLabelClassName}
                  currentValue={filter}
                  handlerFuction={(e) => setFilter(e.target.value)}
                />

                <RadioButton
                  id="filter-option-dog"
                  radioValue="dog"
                  subtitle={
                    <span className="inline-flex items-center">
                      <span>Pieska!</span>
                      <FontAwesomeIcon
                        className={clsx(theme.textAccentLight, "ml-2 text-3xl")}
                        icon={faDog}
                      />
                    </span>
                  }
                  labelClassName={radioButtonLabelClassName}
                  currentValue={filter}
                  handlerFuction={(e) => setFilter(e.target.value)}
                />
              </RadioButtonsWrapper>
              <button
                onClick={() => setFilter("")}
                type="button"
                className={clsx(
                  theme.bgStyleLight,
                  isHeightContrastEnable && "text-white",
                  "my-4 w-full space-x-2 px-6 py-3 md:bg-auto"
                )}
              >
                Wyczyść
              </button>
            </form>
            <div className="grid grid-cols-12 gap-2 p-4 md:gap-10">
              {filteredAnimals.length === 0 ? (
                <ErrorMessage className="col-span-10 col-start-2 min-h-50-screen bg-white md:col-span-8 md:col-start-3" />
              ) : (
                filteredAndSortedAnimals.map((animal) => (
                  <Card
                    key={animal.id}
                    linkTo={createAnimalDetailsUrl(animal.shelter_id)}
                    theme={theme}
                    className="col-span-12 md:col-span-6 xl:col-span-4"
                    title={animal.name}
                    subtitle={`Data przyjęcia: ${dateDisplay(
                      animal.date_admission
                    )}`}
                    additionalSubtitleExtension={`Wiek:  ${createStringAge(
                      animal?.date_birthday
                    )}`}
                    descriptionHtml={removeDomElement(animal.description)}
                    gatsbyImage={getImage(
                      animal.photos?.[0]?.directus_files_id?.imageFile
                    )}
                    gatsbyImageAlt={
                      animal.photos?.[0]?.directus_files_id?.description ?? ""
                    }
                    variant="default"
                  />
                ))
              )}
            </div>
          </SectionContainer>
        </div>
      </div>
    </Layout>
  );
};

export default AdoptionsPage;

export const query = graphql`
  query AdoptionsPage {
    directus {
      animal(filter: { status: { _eq: "adoption_ready" } }) {
        id
        shelter_id
        status
        date_created
        name
        date_birthday
        date_admission
        description
        kind
        photos(limit: 1) {
          directus_files_id {
            id
            description
            imageFile {
              childImageSharp {
                id
                gatsbyImageData(
                  quality: 80
                  width: 500
                  aspectRatio: 1.4
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
      heroAnimal: animal(
        filter: { status: { _eq: "adoption_ready" } }
        limit: 1
        sort: "date_birthday"
      ) {
        photos(limit: 1) {
          directus_files_id {
            id
            description
            imageFile {
              id
              previewImage: childImageSharp {
                resize(
                  width: 1200
                  height: 630
                  quality: 85
                  cropFocus: ATTENTION
                  fit: COVER
                ) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
